@import "src/scss/Mixins";

$largeFontSize: 7em;
$lightFontSize: 4em;
$fontWeight: 900;

.pageHeader {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    padding: 1em;
}

.pageContainer {
    background-color: black;
    z-index: 3;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    position: fixed;
    pointer-events: auto;
    overflow-y: auto;
    &-home{
        z-index: 1;
        background-color: transparent;
    }
}

.pageTitle {
    font-size: $largeFontSize;
    font-weight: $fontWeight;
    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}


.pageContent{
    @include paddingLeft;
    @include paddingRight;
    padding-top: 4em;
    line-height: 3em;
    h3 {
        font-size: 2em;
    }
}

a {
    @include stretchLink;
}

@media (max-width: 768px) {
    .pageTitle{
        font-size: $lightFontSize;
    }
    .pageContent{
        h3 {
            font-size: 1.5em;
        }
    }
}

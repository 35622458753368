.control{
    display: flex;
    align-items: center;
    border: 1px dotted white;
    color: white;
}

.topControl{
    height: 18svh;
    position: fixed;
    top: 0;
    opacity: 0.5;
    width: 96%;
    margin: 1svh 2%;
    justify-content: center;
}

.bottomControl{
    height: 18svh;
    position: fixed;
    bottom: 0;
    opacity: 0.5;
    width: 96%;
    margin: 1svh 2%;
    justify-content: center;
}

.leftControl{
    height: 58svh;
    position: fixed;
    top: 20svh;
    left: 0;
    width: 46%;
    margin: 1svh 2%;
    opacity: 0.5;
    justify-content: left;
}

.rightControl{
    height: 58svh;
    position: fixed;
    top: 20svh;
    right: 0;
    width: 46%;
    margin: 1svh 2%;
    opacity: 0.5;
    justify-content: right;
}

.label{
    position: fixed;
    z-index: 1;
    width: 70%;
    margin-left: 15%;
    top: 70svh;
    text-align: center;
    color: white;
}

.icon{
    color: white;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}
@font-face {
    font-family: "Gunship";
    font-weight: 400;
    font-style: normal;
    src: url("./fonts/gunship.woff2") format("woff2");
}

@font-face {
    font-family: "Beachsound";
    font-weight: 400;
    font-style: normal;
    src: url("./fonts/beachsound.woff2") format("woff2");
}

@font-face {
    font-family: "MidnightDrive";
    font-weight: 400;
    font-style: normal;
    src: url("./fonts/midnightDrive.woff2") format("woff2");
}

@font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-style: normal;
    src: url("./fonts/poppins.woff2") format("woff2");
}

@font-face {
    font-family: "AlongSansMedium";
    font-weight: 400;
    font-style: normal;
    src: url("./fonts/alongSansMedium.woff2") format("woff2");
}

@font-face {
    font-family: "AlongSans";
    font-weight: 400;
    font-style: normal;
    src: url("./fonts/alongSans.woff2") format("woff2");
}
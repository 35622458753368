.closeButtonContainer {
    text-align: right;
    margin-left: auto;
}

.closeButton {
    font-size: 2em;
    color: white;
    border: none;
    padding: 0;
    transform: scale(1.3, 1);
    line-height: 0.7;
    &:hover{
        opacity: 0;
        transition-duration: 0.5s;
    }
}

@import "src/scss/Mixins";

.title{
    @include gameFont;
    font-size: 1.5em;
}

.container{
    padding: 2em 1em;
    text-align: center;    
}

.score{
    @include gameFont;
    background: $gradient;
    font-size: 3em;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

//media queries

@media (max-width: 768px) {
    .score{
        font-size: 1.5em;
    }
}
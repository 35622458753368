@import "src/scss/Mixins";

.listItem{
    line-height: 1em;
    cursor: pointer;
    > a { 
        @include gameFont;
        font-size: 3.5em;
        text-decoration: none;
        background: $gradient;
        height: 100vh;
        &:hover{
            background: linear-gradient(-45deg, #dfdfdf, #f0f0f0, #e5e5e5, #ffffff);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: 400% 400%;
            animation: gradient 15s ease infinite;
            letter-spacing: 1em;
            transition-duration: 0.5s;
        }
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 400% 400%;
        animation: gradient 15s ease infinite;
    }
    &:not(:last-child){
        margin-bottom: 1.7em;
    }
}

//media queries

@media (max-width: 768px) {
    .listItem{
        font-size: 0.8em;
        > a {
            &:hover{
                letter-spacing: 0.5em;
            }
        }
    }  
}

@media (min-width: 768px) and (max-width: 1024px) {
    .listItem{
        font-size: 0.9em;
        > a {
            &:hover{
                letter-spacing: 0.3em;
            }
        }
    }  
}
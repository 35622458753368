@import "src/scss/Mixins";

.listItem{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    &:not(:first-child){
        padding-top: 1em;
    }
}

.column{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-align: right;
}

.list{
    @include gameFont;
    list-style-type: none;
    padding: 0;
}

.title{
    @include gameFont;
    text-align: right;
    font-size: 2em;
    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.icon{
    width: 20px;
    height: 20px;
    margin-left: 70%;
    background-size: 200% 200%;
    text-align: center;
}


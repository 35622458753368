@import "src/scss/Mixins";

.label{
    @include gameFont;
    text-align: center;
    font-size: 2em;
    color: white;
}

//media queries

@media (max-width: 768px) {
    .label{
        font-size: 1em;
    }
}

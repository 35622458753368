@mixin gameFont {
    font-family: "Gunship";
}

@mixin fixedCentered {
	position: fixed;
	top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	text-align: center;
}

@mixin fixedCenteredFullWidth {
	position: fixed;
	top: 50%;
    transform: translate(-50%);
	text-align: center;
	width: 100vw;
}

$paddingH: 1em;
$paddingW: 1em;

@mixin paddingLeft {
	padding-left: $paddingW;
}

@mixin paddingRight {
	padding-right: $paddingW;
}

@mixin paddingTop {
	padding-top: $paddingH;
}

@mixin paddingBottom {
	padding-bottom: $paddingH;
}

@mixin stretchLink {
	color: white;
	text-decoration: underline;
	&:hover{
		letter-spacing: 0.3em;    
		transition-duration: 0.5s;
	}
}

@mixin standardLink {
	color: white;
	text-decoration: underline;
	&:hover{
		text-decoration: none;
	}
}

$gradient: linear-gradient(-45deg, #08e2ff, #ff2c7d, #ff1fcb, #1fffcb);

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

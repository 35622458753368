@import "src/scss/Mixins";

.title{
    @include gameFont;
}

.playerContainer{
    color: white;
}

.radioLink{
    @include stretchLink;
}

//media queries

@media (max-width: 768px) {
    .title{
        font-size: 1em;
    }
    .advertisement{
        font-size: 0.8em;
    }    
}
@import "src/scss/Mixins";

.text{
    @include gameFont;
    font-size: 2em;
}

//media queries

@media (max-width: 768px) {
    .text{
        font-size: 1em;
    }
}

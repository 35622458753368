@import "Fonts";
@import "src/scss/Mixins";
@import "general/Pages";

html {
  margin: 0;
  padding: 0;
  height: -webkit-fill-available;
}

body {
  color: white;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.navMenu{
  bottom: 0;
  left: -100%;
  list-style-type: none;
  position: fixed;
  padding: 0;
  margin: 0;
  z-index: 2;
  @include paddingBottom;
  @include paddingLeft;
}

.gameTutorial{
  color: white;
  position: fixed;
  right: -100%;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 1;
  width: 350px;
  @include paddingRight;
}

.radioPlayer{
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 2;
  @include paddingLeft;
  @include paddingTop;
}

.graphicsControls{
  position: fixed;
  bottom: 0;
  right: -100%;
  z-index: 1;
  @include paddingRight;
  @include paddingBottom;
}

.gameInstructions{
  position: fixed;
  right: 0;
  @include fixedCentered;
  z-index: 1;
}

.mobileControls{
  position: fixed;
  opacity: 0;
  z-index: 2;
}

.mobileSoundControl{
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  @include paddingRight;
  @include paddingTop;
}

.levelLabel{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  margin-bottom: 2em;
}

.scoreCounter{
  position: fixed;
  top: -200px;
  right: 0;
  z-index: 1;
  width: 100%;
  text-align: center;
  padding-top: 10vh;
}

.gameEndingCountdown{
  position: fixed;
  bottom: -100px;
  text-align: center;
  pointer-events: none;
  width: 100%;
}

.gameSummary{
  position: fixed;
  width: 50%;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0;
}


button {
  color: white;
  background-color: transparent;
  border: 2px solid white;
  padding: 5px 15px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
  /* &:hover{
      opacity: 0.5;
      transition-duration: 0.5s;    
  } */
}


//media queries

@media (max-width: 768px) {
  .graphicsControls, .gameTutorial {
    display: none;
  }
  .mobileSoundControl {
    display: block;
  }
}

@media (min-width: 768px) {
  .mobileControls {
    display: none;
  }
}
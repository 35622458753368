@import "src/scss/Mixins";

.overlay{
    z-index: 5;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.223);
    height: 100vh;
    width: 100vw;
}

.message{
    text-align: center;
}

.buttonsContainer{
    text-align: center;
    transform: translateY(-50%);
}

.container{
    background: $gradient;
    position: absolute;
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    height: auto;
    width: 50%;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    box-shadow: 12px 12px 2px 1px rgba(255, 16, 219, 0.2);
}
@import "src/scss/Mixins";

.socials{
    @include paddingLeft;
    display: flex;
}

.logo{
    width: 2em;
    height: 2em;
    fill: white;
    cursor: pointer;
    &:hover{
        opacity: 0.5;
        transition-duration: 1s;
    }
}

.link{
    background: none;
    &:not(:first-child){
        padding-left: 0.5em;
    }
}
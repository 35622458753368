@import "src/scss/Mixins";

.title{
    @include gameFont;
    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    text-align: right;
}

.graphicsContainer{
    padding: 2em 3em;
}
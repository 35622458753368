@import "src/scss/Mixins";

.instructionsContainer{
    @include gameFont;
    font-size: 4em;
    background: $gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    text-align: center;
    opacity: 0;
}

//media queries

@media (max-width: 768px) {
    .instructionsContainer{
        font-size: 1.2em;
    }
}

@import "src/scss/Mixins";

.test{
    color: white;
    background-color: transparent;
    border: 2px solid white;
    padding: 5px 15px;
    border-radius: 5px;
    margin-left: 5px;
    z-index: 100;
    border: 1px solid white;
    position: fixed;
    top: 60svh;
    left: 50%;
    text-align: center;
    transform: translate(-50%);
}

.container{
    height: 100svh;
    width: 100vw;
    position: fixed;
    z-index: 5;
}

.titleContainer{
    @include fixedCentered;
    z-index: 2;
}

.subtitleContainer{
    @include fixedCentered;
    z-index: 99;
    opacity: 0;
}

.title{
    font-size: 10em;
    font-family: "MidnightDrive";
    position: relative;
    text-align: center;
    color: #f785ff;
}

.bolded{
    font-weight: 900;
}

.subtitle{
    text-transform: uppercase;
    font-size: 1.2em;
    letter-spacing: 0.6em;
    text-align: center;
}

.topLetterBoxing{
    height: 50%;
    background-color: rgb(0, 0, 0);
}

.bottomLetterBoxing{
    position: relative;
    height: 50%;
    background-color: black;
}

.startSiteButton{
    width: 100px;
    height: auto;
}

//media queries

@media (max-width: 768px) {
    .title{
        font-size: 5em;
    }
    .subtitle{
        text-transform: uppercase;
        font-size: 0.9em;
        letter-spacing: 0.4em;
    }
    .subtitleContainer{
        @include fixedCenteredFullWidth;
        z-index: 2;
        opacity: 0;
    }
}
